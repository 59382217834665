import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppConsts from "../App_Consts";
import {
  CalculateTotal,
  IsVaildItem,
  CalculateItemTotal,
  FindOptionItem,
  HasStoreCredit,
} from "./CheckoutUtility";
import {
  resetItemsInCart,
  updatePaymentMethod,
  UpdateUserInfo,
} from "../Slices/OrderedItemsSlice";
import { auto, left } from "@popperjs/core";
import InputForm from "../Utilities/InputForm";
import ContactWithVerification from "../Utilities/ContactWithVerification";
import { useCookies } from "react-cookie";
import {
  GetVerificationInfo,
  GetVerificationInfoFromCookie,
  GetUserInfoFromCookie,
} from "../Users/UserInfoUtility";
import UseStoreCreditAtPayment from "../StoreCredit/UseStoreCreditAtPayment";
import Logger from "../Utilities/Logger";
import CheckoutBody from "./CheckoutBody";

function CheckoutPayment() {
  const appId = useSelector((state) => {
    return state.ordredItem.appId;
  });
  const locationId = useSelector((state) => {
    return state.ordredItem.locationId;
  });
  const opMode = useSelector((state) => {
    return state.ordredItem.opMode;
  });

  const paymentMethodRadio = useSelector((state) => {
    return state.ordredItem.paymentMethod;
  });
  const [requestedAmount, setRequestedAmount] = useState(0);

  const verificationInfo = GetVerificationInfo();

  const userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  const storeCreditCatFromSlice = useSelector((state) => {
    return state.ordredItem.storeCreditCategory;
  });

  console.log("payment userInfo", userInfo);
  const dispatch = useDispatch();

  const fNameUpdator = () => {
    var fNameElem = document.getElementById("fName");

    dispatch(
      UpdateUserInfo({
        userId: userInfo.userId,
        userKey: userInfo.userKey,
        contact: userInfo.contact,
        fName: fNameElem.value,
        lName: userInfo.lName,
      })
    );
  };

  const lNameUpdator = () => {
    var lNameElem = document.getElementById("lName");

    dispatch(
      UpdateUserInfo({
        userId: userInfo.userId,
        userKey: userInfo.userKey,
        contact: userInfo.contact,
        fName: userInfo.fName,
        lName: lNameElem.value,
      })
    );
  };

  const nameUpdator = () => {
    fNameUpdator();
    lNameUpdator();
  };

  const itemsInCart = useSelector((state) => {
    return state.ordredItem.itemsInCart;
  });
  const allData = useSelector((state) => {
    return state.ordredItem.data;
  });
  const menuOptions = useSelector((state) => {
    return state.ordredItem.menuOptions;
  });
  const taxRate = useSelector((state) => {
    return state.ordredItem.taxRate;
  });

  var payOnline = paymentMethodRadio === "online";
  var verifficationRequired = paymentMethodRadio === "store";
  var hasStoreCredit = HasStoreCredit(itemsInCart, storeCreditCatFromSlice);

  if (hasStoreCredit) {
    verifficationRequired = true;
  }
  var contactWidth = "col-lg-6";
  if (verifficationRequired) contactWidth = "col-lg-8";

  var totalResult = CalculateTotal(
    itemsInCart,
    allData,
    menuOptions,
    storeCreditCatFromSlice,
    taxRate
  );
  let subTotal = totalResult.total + totalResult.taxTotal;
  var grandTotal = subTotal.toFixed(2);
  var adjustedGrandTotal = grandTotal - requestedAmount;
  var btnLabel = `Pay \$${adjustedGrandTotal.toFixed(2)}`;
  if (verifficationRequired)
    btnLabel = `Place Order \$${adjustedGrandTotal.toFixed(2)}`;

  var isAllowPay = verificationInfo.isVerified;
  if (!verifficationRequired) isAllowPay = true;

  const isOnlineChecked = paymentMethodRadio !== "store";

  let bodyMaxHeight = window.innerHeight * 0.6;
  var orderedItems = [];
  for (let itemKey in itemsInCart) {
    let item = itemsInCart[itemKey];
    if (IsVaildItem(item, allData)) {
      orderedItems.push(item);
    }
  }

  async function initializeCard(payments) {
    let cardContainer = document.getElementById("card-container");
    const card = await payments.card();
    if (!cardContainer.hasChildNodes()) {
      await card.attach("#card-container");
    }
    return card;
  }

  const displayOrderNumber = (orderNumber) => {
    let orderDiv = document.createElement("div");
    let orderRef = document.createElement("span");
    orderRef.textContent = orderNumber;
    orderDiv.textContent = "Order reference number: ";
    orderDiv.classList.add("checkedOutOrderNumber");
    orderDiv.appendChild(orderRef);
    let textDiv = document.createElement("div");
    textDiv.textContent =
      "Please provide the order reference number when you pick up your order!";
    orderDiv.append(textDiv);
    let checkout = document.getElementsByClassName("CheckoutPayment");
    if (checkout && checkout.length > 0) {
      checkout[0].appendChild(orderDiv);
    }
    document.getElementById("orderDetailTitle").style.visibility = "hidden";
    document.getElementById("payment-form").style.visibility = "hidden";
  };

  const handlePayment = (event) => {
    console.log("Click radio", event.target.value);
    dispatch(
      updatePaymentMethod({
        paymentMethod: event.target.value,
      })
    );
  };

  const displayError = (errorDetails) => {
    if (errorDetails.includes("API:")) {
      errorDetails = errorDetails.replace("Error: API:", "");
    } else if (errorDetails === "") {
      let errorWraper = document.getElementsByClassName(
        "CheckoutPaymentErrorWraper"
      );
      if (errorWraper.length > 0) {
        errorWraper[0].remove();
      }

      return;
    } else return;

    let orderDiv = document.createElement("div");
    orderDiv.textContent = errorDetails;
    orderDiv.className = "text-danger CheckoutPaymentErrorWraper";
    let checkout = document.getElementsByClassName("CheckoutPayment");
    if (checkout && checkout.length > 0) {
      // checkout[0].removeChild();
      checkout[0].appendChild(orderDiv);
    }
  };

  const PlaceOrderPaymentAtStore = async (userKey, contact, reqAmount) => {
    var fName = document.getElementById("fName");
    var lName = document.getElementById("lName");
    Logger.Log("PlaceOrderPaymentAtStore reqAmount:", reqAmount);
    const body = JSON.stringify({
      LocationId: locationId,
      StoreId: AppConsts.STORE_ID,
      Amount: adjustedGrandTotal,
      requestedStoreCreditAmount: reqAmount,
      FName: fName.value,
      LName: lName.value,
      UserKey: userKey,
      Contact: contact,
      PaymentProfile: orderedItems,
    });

    const paymentResponse = await fetch(
      AppConsts.API_URL_BASE + "/Payment/ProcessPaymentAtStore",
      {
        method: "POST",
        body,
      }
    );

    if (paymentResponse.ok) {
      return paymentResponse.json();
    }

    const errorBody = await paymentResponse.text();
    throw new Error(errorBody);
  };

  async function createPayment(token, userKey, contact, reqAmount) {
    var fName = document.getElementById("fName");
    var lName = document.getElementById("lName");
    const body = JSON.stringify({
      LocationId: locationId,
      SourceId: token,
      StoreId: AppConsts.STORE_ID,
      Amount: adjustedGrandTotal,
      requestedStoreCreditAmount: reqAmount,
      FName: fName.value,
      LName: lName.value,
      UserKey: userKey,
      Contact: contact,
      PaymentProfile: orderedItems,
    });

    const paymentResponse = await fetch(
      AppConsts.API_URL_BASE + "/Payment/ProcessPayment",
      {
        method: "POST",
        body,
      }
    );

    if (paymentResponse.ok) {
      return paymentResponse.json();
    }

    const errorBody = await paymentResponse.text();
    throw new Error(errorBody);
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById("payment-status-container");
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
  }

  useEffect(async function () {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    // Checkpoint 2.
    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();
      cardButton.disabled = true;
      console.log("handlePaymentMethodSubmission clicked");
      displayError("");
      try {
        // disable the submit button as we await tokenization and make a payment request.
        var paymentResults = {};
        let isOnline = cardButton.getAttribute("isOnline");
        let userKey = cardButton.getAttribute("userKey");
        let contact = cardButton.getAttribute("contact");
        let reqAmount = cardButton.getAttribute("requestedAmount");
        if (isOnline === "false") {
          //pay at store
          paymentResults = await PlaceOrderPaymentAtStore(
            userKey,
            contact,
            reqAmount
          );
        } else {
          //pay online
          const token = await tokenize(paymentMethod);
          paymentResults = await createPayment(token, userKey, contact);
        }
        displayPaymentResults("SUCCESS");
        dispatch(resetItemsInCart());
        try {
          displayOrderNumber(paymentResults.order);
        } catch (error) {}
      } catch (e) {
        cardButton.disabled = false;
        displayError(e.toString());
        displayPaymentResults("FAILURE");
        console.error(e.message);
      }
    }

    async function ProcessPayment(event) {
      await handlePaymentMethodSubmission(event, card);
    }

    const cardButton = document.getElementById("card-button");
    cardButton.setAttribute("isOnline", isOnlineChecked);
    cardButton.setAttribute("userKey", userInfo.userKey);
    cardButton.setAttribute("contact", verificationInfo.contact);
    cardButton.setAttribute("requestedAmount", requestedAmount);

    let getNumRegValue = cardButton.getAttribute("numReg");

    if (!getNumRegValue) {
      cardButton.addEventListener("click", ProcessPayment);
      cardButton.setAttribute("numReg", 1);
    }
  });

  const CreditStoreUseUpdator = (requestedAmount) => {
    setRequestedAmount(requestedAmount);
  };

  var mode = <div></div>;
  if (opMode !== 1) {
    mode = <div className="operationMode">Development Envrionment</div>;
  }

  var creditStoreUse = <div></div>;
  if (!hasStoreCredit && userInfo.userKey !== -1) {
    creditStoreUse = (
      <div className="row">
        <UseStoreCreditAtPayment
          ExternalUseCreditRequestHandler={CreditStoreUseUpdator}
          TotalPaymentAmount={grandTotal}
        ></UseStoreCreditAtPayment>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="CheckoutPayment col-lg-6 col-sm-12">
        {mode}
        <div className="paymentPlace mb-3 ms-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="payAtStore"
              value="store"
              onChange={handlePayment}
              checked={!isOnlineChecked}
            />
            <label
              className="form-check-label float-start"
              htmlFor="payAtStore"
            >
              Pay at store
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="payOnline"
              value="online"
              onChange={handlePayment}
              checked={isOnlineChecked}
            />
            <label className="form-check-label float-start" htmlFor="payOnline">
              Pay online
            </label>
          </div>
        </div>
        <div className="row">
          <form
            id="userInformation"
            className="col-lg-12 ms-3"
            style={{ textAlign: left }}
          >
            <div
              className="col-lg-6 col-sm-12"
              style={{ display: "inline-block" }}
            >
              <InputForm
                inputLabel="First Name"
                inputId="fName"
                inputValue={userInfo.fName}
                updator={fNameUpdator}
              />
            </div>
            <div
              className="col-lg-6 col-sm-12"
              style={{ display: "inline-block" }}
            >
              <InputForm
                inputLabel="Last Name"
                inputId="lName"
                inputValue={userInfo.lName}
                updator={lNameUpdator}
              />
            </div>
          </form>
          <form id="contactInformation" className={contactWidth}>
            <ContactWithVerification
              inputLabel="Contact Number"
              inputId="contact"
              isVerificationRequired={verifficationRequired}
              onVerified={nameUpdator}
              contactNumber={userInfo.contact}
            />
          </form>
        </div>
        {creditStoreUse}

        <form id="payment-form">
          <div
            id="card-container"
            style={{ display: payOnline ? "" : "none" }}
          ></div>
          <button
            id="card-button"
            className="btn btn-primary col-lg-4"
            type="button"
            disabled={!isAllowPay}
          >
            {btnLabel}
          </button>
        </form>
        <div id="payment-status-container"></div>
      </div>
      <div
        id="orderDetailTitle"
        style={{
          textAlign: left,
          fontSize: 25,
          fontWeight: "bold",
        }}
      >
        Order Details
      </div>
      <div className="col-lg-6 col-sm-12" style={{ textAlign: left }}>
        <CheckoutBody IsViewOrderDetails="true"></CheckoutBody>
      </div>
    </div>
  );
}

export default CheckoutPayment;
