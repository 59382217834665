import { useSelector, useDispatch } from "react-redux";
import {
  updateCatItems,
  updateOptionItems,
  registerToken,
} from "../Slices/OrderedItemsSlice";
import AppConsts from "../App_Consts";
import { useParams } from "react-router-dom";
import MenuItemDetail from "./MenuItemDetail";
import "./Menu.css";
// import { useCookies } from "react-cookie";
import LoadMenuItem from "./MenuUtility";
import { LoadMenuOptionItems } from "./MenuUtility";
import ModalOptions from "./ModalOptions";

function MenuItemsDetails(props) {
  const dispatch = useDispatch();
  const { catId } = useParams();

  var menuCatId = catId;
  if (catId == undefined) {
    menuCatId = props.defaultCatId;
  }

  const token = useSelector((state) => {
    return state.ordredItem.locationId;
  });
  const menuItems = useSelector((state) => {
    if (
      state.ordredItem.data &&
      state.ordredItem.data[menuCatId] &&
      state.ordredItem.data[menuCatId].items.length > 0
    ) {
      let itemFromstate = state.ordredItem.data[menuCatId];

      return itemFromstate;
    }
    return { items: [], id: menuCatId };
  });

  const menuOptions = useSelector((state) => {
    return state.ordredItem.menuOptions;
  });

  const loadAppToken = async () => {
    if (token == undefined || token === "") {
      const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
      let targetUrl = `${AppConsts.API_URL_BASE}/Auth/GetStoreToken?storeId=${encodedStoreId}`;

      let respone = await fetch(targetUrl);
      let jsonData = await respone.json();
      dispatch(
        registerToken({
          locationId: jsonData.locationId,
          appId: jsonData.appId,
          opMode: jsonData.opMode,
          taxRate: jsonData.taxRate,
        })
      );
    }
  };
  loadAppToken();

  const LoadMenuOptionItemsByMenu = (menuKey) => {
    if (menuKey === AppConsts.KEY_UNDEFINED) return;

    let menuOptionsFrom = LoadMenuOptionItems(menuKey);
    menuOptionsFrom.then((value) => {
      dispatch(
        updateOptionItems({
          id: menuKey,
          items: value,
        })
      );
    });
  };

  if (menuItems.items.length === 0) {
    LoadMenuItem(menuCatId).then(
      (value) => {
        dispatch(
          updateCatItems({
            items: value,
            id: menuCatId,
            // menuCookies: cookies.Menus,
          })
        );

        for (let menuItem of value) {
          LoadMenuOptionItemsByMenu(menuItem.PKEY);
        }
      },
      (failReason) => {
        console.log("Failed:", failReason);
      }
    );
  }

  return (
    <div className="MenuItemsWraper">
      <div className="ModalOptionContainer">
        <ModalOptions />
      </div>
      {menuItems.items.map((MenuItem) => {
        return (
          <MenuItemDetail
            key={MenuItem.PKEY}
            menuItemInfo={MenuItem}
            catId={menuCatId}
          />
        );
      })}
    </div>
  );
}

export default MenuItemsDetails;
