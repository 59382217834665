import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantityInCart,
  decrementQuantityInCart,
  generateOrderedKey,
} from "../Slices/OrderedItemsSlice";
// import { useCookies } from "react-cookie";

import { CalculateTotal, IsVaildItem, FindOptionItem } from "./CheckoutUtility";
import { auto } from "@popperjs/core";
import Logger from "../Utilities/Logger";

function CheckoutBody(props) {
  const { IsViewOrderDetails } = props;
  Logger.Log("CheckoutBody IsViewOrderDetails:", IsViewOrderDetails);

  const itemsInCart = useSelector((state) => {
    return state.ordredItem.itemsInCart;
  });
  const taxRate = useSelector((state) => {
    return state.ordredItem.taxRate;
  });
  const allData = useSelector((state) => {
    return state.ordredItem.data;
  });
  const menuOptions = useSelector((state) => {
    return state.ordredItem.menuOptions;
  });
  const storeCreditCatFromSlice = useSelector((state) => {
    return state.ordredItem.storeCreditCategory;
  });

  const dispatch = useDispatch();

  var totalResult = CalculateTotal(
    itemsInCart,
    allData,
    menuOptions,
    storeCreditCatFromSlice,
    taxRate
  );

  var subTotal = totalResult.total;
  var tax = totalResult.taxTotal;
  var grandTotal = totalResult.total + totalResult.taxTotal;
  var orderedItems = [];
  for (let itemKey in itemsInCart) {
    let item = itemsInCart[itemKey];
    if (IsVaildItem(item, allData)) {
      orderedItems.push(item);
    }
  }

  if (!orderedItems || orderedItems.length === 0) {
    return <div className="CheckoutBody NoItems">No Items in cart</div>;
  }
  let bodyMaxHeight = window.innerHeight * 0.7;

  const orderControl = (menuItemInfo, optionSelected, item, isDetailsView) => {
    if (isDetailsView === "true") return <div className="col-4"></div>;
    else
      return (
        <div className="col-4">
          <div className="btn-group cartBtn col-3 ControlBtnGrp">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  decrementQuantityInCart(
                    generateOrderedKey(menuItemInfo.PKEY, optionSelected)
                  )
                )
              }
              className="btnLeft btnCountControl btn btn-info"
            >
              -
            </button>
            <span className="CountInfo">{item.count}</span>
            <button
              type="button"
              onClick={() =>
                dispatch(
                  incrementQuantityInCart(
                    generateOrderedKey(menuItemInfo.PKEY, optionSelected)
                  )
                )
              }
              className="btnRight btnCountControl btn btn-info"
            >
              +
            </button>
          </div>
        </div>
      );
  };

  return (
    <div className="CheckoutBody">
      <table
        className="contentsBody col-lg-12 mb-2"
        style={{ maxHeight: bodyMaxHeight, overflow: auto }}
      >
        {orderedItems.map((item) => {
          var menuItemInfo = allData[item.catId].items.find(
            (element) => element.PKEY === item.menuKey
          );
          let optionSelected = [];
          if (item.optionsSelected !== undefined) {
            optionSelected = item.optionsSelected;
          }
          var price = parseFloat(menuItemInfo.PRICE) * item.count;
          return (
            <tbody>
              <tr className="container-fluid mb-0" key={item.menuKey}>
                <td className="col-6">
                  <h5>{menuItemInfo.NAME}</h5>
                </td>
                <td className="col-3">
                  {orderControl(
                    menuItemInfo,
                    optionSelected,
                    item,
                    IsViewOrderDetails
                  )}
                </td>
                <td className="col-3">
                  <h5>${price.toFixed(2)}</h5>
                </td>
              </tr>

              {optionSelected.map((selecteOptionKey) => {
                let optionItem = FindOptionItem(
                  selecteOptionKey,
                  menuItemInfo.PKEY,
                  menuOptions
                );
                if (optionItem) {
                  let optionPrice = optionItem.PRICE * item.count;
                  if (item.catId == storeCreditCatFromSlice) {
                    optionPrice = 0;
                  }
                  return (
                    <tr>
                      <td className="col-6">- {optionItem.NAME}</td>
                      <td className="col-3"></td>
                      <td className="col-3">${optionPrice}</td>
                    </tr>
                  );
                }
                return <tr></tr>;
              })}
            </tbody>
          );
        })}
      </table>
      <table className="CheckOutInfoContainer col-12 pt-3">
        <tbody>
          <tr>
            <td>Subtotal:</td>
            <td>${subTotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tax:</td>
            <td>${tax.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Grand Total:</td>
            <td>${grandTotal.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CheckoutBody;
