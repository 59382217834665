import AppConsts from "../App_Consts";
import {
  registerVerificationToken,
  UpdateUserInfo,
} from "../Slices/OrderedItemsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import React, { useState, useEffect } from "react";
import Logger from "./Logger";

function ContactWithVerification(props) {
  const dispatch = useDispatch();
  const {
    inputId,
    inputLabel,
    isVerificationRequired,
    onVerified,
    contactNumber,
  } = props;
  const aria_label = "Enter " + inputLabel;

  const [currentVerificationInfo, setcurrentVerificationInfo] = useState([]);

  var verificationInfo = useSelector((state) => {
    return state.ordredItem.verificationInfo;
  });

  const userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  const [cookies, setCookie] = useCookies(["verification"]);
  if (
    verificationInfo.contact.length === 0 &&
    !verificationInfo.isVerified &&
    cookies.verification &&
    cookies.verification.isVerified
  ) {
    verificationInfo = cookies.verification;
  }
  Logger.Log("ContactWithVerification cookies:", cookies);
  if (cookies.verification && cookies.verification.editRequested) {
    verificationInfo = cookies.verification;
  }

  const EditContact = async () => {
    setCookie("verification", {
      verificationToken: verificationInfo.verificationToken,
      contact: "",
      isVerified: false,
      editRequested: true,
    });
    let newValue = {};
    setcurrentVerificationInfo(newValue);
  };

  var msgText = "";
  if (isVerificationRequired)
    msgText = "Contact Number must be verified to place an order";
  if (verificationInfo.isVerified) msgText = "Verified";

  var btnLabel = "Send Code";
  var verificationInput = "";
  if (
    verificationInfo.contact.length > 0 &&
    verificationInfo.verificationToken.length > 0
  ) {
    verificationInput = (
      <input
        type="text"
        className="form-control col-lg-1"
        placeholder="Enter code"
        aria-label="Enter code"
        aria-describedby="basic-addon1"
        id="verificationCode"
      />
    );
    btnLabel = "Verify Code";
  }

  const SendCode = async () => {
    var contactElement = document.getElementById(inputId);
    const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
    let targetUrl = `${AppConsts.API_URL_BASE}/Auth/SendCode?storeId=${encodedStoreId}&contactNumber=${contactElement.value}&userKey=${userInfo.userKey}`;
    if (cookies.verification && cookies.verification.editRequested) {
      targetUrl += "&editRequested=true";
    }
    Logger.Log("SendCode", targetUrl);
    const sendCodeResponse = await fetch(targetUrl);
    let jsonData = await sendCodeResponse.json();
    var msg = document.getElementById("labelText");
    if (sendCodeResponse.ok) {
      Logger.Log("SendCode jsonData:", jsonData);
      if (jsonData.isVerified) {
        await PerformVerification(verificationInfo);
      } else {
        dispatch(
          registerVerificationToken({
            verificationToken: jsonData.verificationToken,
            contact: contactElement.value,
          })
        );

        setCookie("verification", {
          verificationToken: jsonData.verificationToken,
          contact: contactElement.value,
          isVerified: false,
        });
        msg.innerText = "Verification code has been sent";
      }
    } else {
      msg.innerText = jsonData.message;
    }
  };

  const PerformRegisterVerification = async (infoVerified) => {
    dispatch(
      registerVerificationToken({
        verificationToken: infoVerified.verificationToken,
        contact: infoVerified.contact,
        isVerified: true,
      })
    );
  };

  const PerformUpdateUserInfo = async (userInfo, infoVerified) => {
    dispatch(
      UpdateUserInfo({
        userId: userInfo.userId,
        userKey: userInfo.userKey,
        contact: infoVerified.contact,
        fName: userInfo.fName,
        lName: userInfo.lName,
      })
    );
  };

  const PerformVerification = async (infoVerified) => {
    setCookie("verification", {
      verificationToken: infoVerified.verificationToken,
      contact: infoVerified.contact,
      isVerified: true,
    });
    if (onVerified) {
      onVerified(infoVerified);
    }
  };

  const VerifyCode = async () => {
    var verificationCodeElem = document.getElementById("verificationCode");
    var contactElement = document.getElementById(inputId);
    const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
    let targetUrl = `${AppConsts.API_URL_BASE}/Auth/VerifyCode?storeId=${encodedStoreId}&contactNumber=${contactElement.value}&verificationCode=${verificationCodeElem.value}&userKey=${userInfo.userKey}`;
    const verifyCodeResponse = await fetch(targetUrl);
    if (verifyCodeResponse.ok) {
      await PerformRegisterVerification(verificationInfo);
      await PerformUpdateUserInfo(userInfo, verificationInfo);
      await PerformVerification(verificationInfo);
    } else {
      let jsonData = await verifyCodeResponse.json();
      var msg = document.getElementById("labelText");
      msg.innerText = jsonData.message;
    }
  };

  var verifyBtn = "";
  if (isVerificationRequired) {
    if (
      verificationInfo.editRequested ||
      verificationInfo.verificationToken.length == 0
    ) {
      verifyBtn = (
        <button type="button" className="btn btn-primary" onClick={SendCode}>
          {btnLabel}
        </button>
      );
    } else {
      verifyBtn = (
        <button type="button" className="btn btn-primary" onClick={VerifyCode}>
          {btnLabel}
        </button>
      );
    }
  }

  var contactInput = "";
  if (verificationInfo.isVerified) {
    contactInput = (
      <div>
        <label>{verificationInfo.contact}</label>
        <button className="btn btn-primary ms-5" onClick={EditContact}>
          Edit
        </button>
      </div>
    );
    verificationInput = "";
    verifyBtn = "";

    var msg = document.getElementById("labelText");
    if (msg) {
      msg.innerText = "Verified";
    }
  } else {
    contactInput = (
      <input
        type="text"
        className="form-control"
        placeholder={inputLabel}
        aria-label={aria_label}
        aria-describedby="basic-addon1"
        id={inputId}
        defaultValue={contactNumber}
      />
    );
  }

  return (
    <div className="md-form mb-3 ms-3">
      <label className="form-label float-lg-start">
        {inputLabel}:
        <span id="labelText" className="ms-2 text-danger">
          {msgText}
        </span>
      </label>
      <div className="input-group">
        {contactInput}
        {verificationInput}
        {verifyBtn}
      </div>
    </div>
  );
}

export default ContactWithVerification;
