import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AppConsts from "../App_Consts";
import Logger from "../Utilities/Logger";

function UseStoreCreditAtPayment(props) {
  const { ExternalUseCreditRequestHandler, TotalPaymentAmount } = props;

  var userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  var initialBalance = 0;
  useEffect(async () => {
    let currentBalance = await RetrieveCurrentStoreCreditBalance();
    setcurrentStoreCreditBalnace(currentBalance);
  }, []);

  const [currentStoreCreditBalnace, setcurrentStoreCreditBalnace] = useState(
    []
  );
  const [requestedAmount, setRequestedAmount] = useState(0);

  const RetrieveCurrentStoreCreditBalance = async () => {
    const body = JSON.stringify({
      UserKey: userInfo.userKey,
    });
    const respone = await fetch(
      AppConsts.API_URL_BASE + "/StoreCredit/GetCurrentStoreCreditBalance",
      {
        method: "POST",
        body,
      }
    );
    let jsonData = await respone.json();
    Logger.Log(
      "RetrieveTranstions RetrieveCurrentStoreCreditBalance",
      jsonData
    );

    return jsonData;
  };

  const UseCreditRequestHandler = async () => {
    let creditAmount = document.getElementById("CreditStoreUseAmount");
    let currentBalance = await RetrieveCurrentStoreCreditBalance();
    let requestedAmount = parseFloat(creditAmount.value);
    let newBalance = parseFloat(currentBalance) - requestedAmount;
    let payAmount = parseFloat(TotalPaymentAmount);
    if (newBalance >= 0 && payAmount >= requestedAmount) {
      setcurrentStoreCreditBalnace(newBalance.toFixed(2));
      setRequestedAmount(requestedAmount);
      ExternalUseCreditRequestHandler(requestedAmount);
    }
  };

  var requestedDetails = "";
  if (requestedAmount > 0) {
    requestedDetails = (
      <div
        className="requestedDetails col-12 text-start mb-2"
        style={{ display: "inline-flex" }}
      >
        <div className="col-lg-4 col-sm-12">Requested Store Credit amount:</div>
        <div
          className="col-6"
          style={{ color: "lightpink", fontWeight: "bold" }}
        >
          ${requestedAmount}
        </div>
      </div>
    );
  }

  return (
    <div className="col-12 mb-4 ms-3">
      <div
        className="currentBalance col-12 text-start mb-2"
        style={{ display: "inline-flex" }}
      >
        <div className="col-lg-4 col-sm-12">Current Store Credit Balance:</div>
        <div
          className="col-6"
          style={{ color: "lightgreen", fontWeight: "bold" }}
        >
          ${currentStoreCreditBalnace}
        </div>
      </div>
      {requestedDetails}
      <div className="row col-12">
        <div
          className="UseCredit col-12 text-start"
          style={{ display: "inline-flex" }}
        >
          <label className="form-label float-lg-start col-lg-4">
            Amount to use Store Credit:
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control me-2"
              aria-describedby="basic-addon1"
              id="CreditStoreUseAmount"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={UseCreditRequestHandler}
            >
              Use Store Credit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseStoreCreditAtPayment;
